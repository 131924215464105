import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from '../../layout/widgets/icon/icon.component';

@Component({
  selector: 'app-delete-button',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './delete-button.component.html',
  styleUrl: './delete-button.component.scss'
})
export class DeleteButtonComponent {
  @Input() label: string = 'Delete';
  @Output() deleteRequest = new EventEmitter();

  requestAddItem() {
    this.deleteRequest.emit();
  }
}

