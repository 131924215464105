import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from '../../layout/widgets/icon/icon.component';

@Component({
  selector: 'app-edit-button',
  standalone: true,
  imports: [IconComponent],
  templateUrl: './edit-button.component.html',
  styleUrl: './edit-button.component.scss'
})
export class EditButtonComponent {
  @Input() label: string = 'Edit';
  @Output() editRequest = new EventEmitter();

  requestAddItem() {
    this.editRequest.emit();
  }
}

